export const archives = {

    init: function () {
        archives.mobileFilters()
    },

    mobileFilters() {
        let $filtersWrap = document.querySelector('.archive-filters__wrap');

        if (!$filtersWrap) { return; }

        let $btnToggleFilters = $filtersWrap.querySelector('.archive-filters__toggle button');
        let $filtersFields = $filtersWrap.querySelector('.archive-filters__fields');
        let $btnToggleFieldset = $filtersWrap.querySelectorAll('.form-filter__label');

        $btnToggleFilters.addEventListener('click', (e) => {
            // alert('toggle')
            $btnToggleFilters.classList.toggle('active');
            $filtersFields.classList.toggle('visible');
        });

        Array.prototype.forEach.call($btnToggleFieldset, ($btn) => {
            let $btnOthers = [];

            $btn.addEventListener('click', () => {
                $btn.classList.toggle('visible');
                $btnOthers = Array.from($btnToggleFieldset).filter(node => node !== $btn);
                
                // console.log($btnOthers);
                Array.prototype.forEach.call($btnOthers, ($btn) => {
                    $btn.classList.remove('visible');
                });
            });

        });

    },


}
