const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export function mobileBreakpoint() {
    return 768;
}

export function headerBreakpoint() {
    return 1024;
}

export const mobileHandler = {
    init: () => {

        mobileHandler.isMobile();
        mobileHandler.setRealVH();
        mobileHandler.mobileNav();
        // mobileHandler.ux();

        window.addEventListener('resize', () => {
            mobileHandler.isMobile();
            mobileHandler.setRealVH();
            // clearTimeout(mobileNav);
            // mobileNav = setTimeout(mobileHandler.mobileNav(), 500);
            // mobileHandler.ux();
        });

        // mobileHandler.debug();

    },

    isMobile: () => {
        let isMobile = getViewportWidth() < mobileBreakpoint();
        if (isMobile) {
            document.body.classList.add('mobile');
        } else {
            document.body.classList.remove('mobile');
        }
    },

    setRealVH: () => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    mobileNav: () => {
        let isMobile = getViewportWidth() < headerBreakpoint();
        let $burger = document.querySelector('.btn--toggle-menu');


        if ($burger) {
            let $menuParentWrap = document.querySelector('.header--main-wrap');
            let $navMain = $menuParentWrap.querySelector('.nav--main .menu-wrap > ul');
            document.addEventListener('click', (e) => {
                if (e.target === $burger) {
                    $burger.classList.toggle('opened');
                    $menuParentWrap.classList.toggle('opened');
                    mobileHandler.closeAllSubmenus();
                    if ($menuParentWrap.classList.contains('opened')) {
                        $burger.classList.remove('closed');
                        disableBodyScroll($navMain);
                    } else {
                        $burger.classList.add('closed');
                        enableBodyScroll($navMain);
                    }
                }
            })
        }

        /** MANAGE SUBMENU **/
        let $anchorSubmenus = document.querySelectorAll('.has-submenu > a, .has-megamenu > a');
        if ($burger && $anchorSubmenus) {

            Array.prototype.forEach.call($anchorSubmenus, ($anchorSubmenu) => {
                if (isMobile) {
                    $anchorSubmenu.addEventListener('click', mobileHandler.toggleSubmenu);
                } else {
                    $anchorSubmenu.removeEventListener('click', mobileHandler.toggleSubmenu);
                }
            });

            // Auto close submenus when clicking outside
            let $navContent = document.querySelector('.menu-wrap li.has-submenu, .menu-wrap li.has-megamenu');
            if ($navContent) {
                document.addEventListener('click', (el) => {
                    if (el.target !== $navContent && !$navContent.contains(el.target)) {
                        let target = el.target.closest('a');
                        mobileHandler.closeAllSubmenus(target);
                    }
                });
            }
        }

        /** MANAGE MEGAMENU **/
        let $anchorMegamenus = document.querySelectorAll('.megamenu-section > a');
        if ($burger && $anchorMegamenus) {

            Array.prototype.forEach.call($anchorMegamenus, ($anchorMegamenu) => {
                if (isMobile) {
                    $anchorMegamenu.addEventListener('click', mobileHandler.toggleMegamenu);
                } else {
                    $anchorMegamenu.removeEventListener('click', mobileHandler.toggleMegamenu);
                }
            });

            // Auto close submenus when clicking outside
            // let $navContent = document.querySelector('.menu-wrap li.has-submenu, .menu-wrap li.has-megamenu');
            // if ($navContent) {
            //     document.addEventListener('click', (el) => {
            //         if (el.target !== $navContent && !$navContent.contains(el.target)) {
            //             let target = el.target.closest('a');
            //             mobileHandler.closeAllSubmenus(target);
            //         }
            //     });
            // }
        }
    },

    toggleSubmenu: (el) => {
        el.preventDefault();
        let target = el.target.closest('a');

        // console.log("submenu "+target);

        if (target.matches('.submenu-parent, .megamenu-parent') && getViewportWidth() < headerBreakpoint()) {
            
            mobileHandler.closeAllSubmenus(target);

            let $parentToToggle = target.closest('li.has-submenu, li.has-megamenu');
            let $parentIsOpened = $parentToToggle.classList.contains('expanded') ? true : false;

            if (!$parentIsOpened || ($parentIsOpened && target.getAttribute('href') === '#')) {
                $parentToToggle.classList.toggle('expanded');
            } else {
                window.location = target.href;
            }

        }
    },

    toggleMegamenu: (el) => {
        el.preventDefault();
        // console.log(el, el.target, el.target.closest('.megamenu-section'));
        let target = el.target.closest('a');

        
        if (target.closest('.megamenu-section') && getViewportWidth() < headerBreakpoint()) {
            
            mobileHandler.closeAllMegamenus(target);

            let $parentToToggle = target.closest('.megamenu-section');
            let $parentIsOpened = $parentToToggle.classList.contains('expanded') ? true : false;

            if (!$parentIsOpened || ($parentIsOpened && target.getAttribute('href') === '#')) {
                $parentToToggle.classList.toggle('expanded');
            } else {
                window.location = target.href;
            }

        }
    },

    closeAllSubmenus: (target) => {
        let $submenus = document.querySelectorAll('li.has-submenu, li.has-megamenu');
        if (target) {
            // console.log(target.closest('.megamenu-section'));
            // let selectors = target.closest('.megamenu-section') ? target.closest('li.has-megamenu .megamenu-section') : target.closest('li.has-submenu, li.has-megamenu');
            $submenus = Array.from($submenus).filter(node => node !== target.closest('li.has-submenu, li.has-megamenu'));
        }
        if ($submenus) {
            Array.prototype.forEach.call($submenus, ($submenu) => {
                $submenu.classList.remove('expanded');
            });
        }
    },

    closeAllMegamenus: (target) => {
        let $megamenus = document.querySelectorAll('.megamenu-section');
        if (target) {
            $megamenus = Array.from($megamenus).filter(node => node !== target.closest('.megamenu-section'));
        }
        if ($megamenus) {
            Array.prototype.forEach.call($megamenus, ($megamenu) => {
                $megamenu.classList.remove('expanded');
            });
        }
    },

    ux: () => {

    },

    debug: () => {
        let vw = getViewportWidth();
        console.log('vw: ' + vw);
        window.addEventListener('resize', () => {
            vw = getViewportWidth();
            console.log('vw: ' + vw);
        });
    }
}

export function getViewportWidth() {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    return viewportWidth;
}