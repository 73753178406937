export const searchMain = {

	init: () => {

		let $btnToggleSearch = document.querySelector('.btn--toggle-search');
		if (!$btnToggleSearch) return;

		let $formSearch = document.querySelector('.header--formsearch-wrap');
		let $formSearchInput = $formSearch.querySelector('input[type="text"]');

		$btnToggleSearch.addEventListener('click', () => {
			$btnToggleSearch.classList.toggle('focus');
			$formSearch.classList.toggle('hidden');
			if (!$formSearch.classList.contains('hidden')) {
				$formSearchInput.focus();
			}
		});

		document.addEventListener('click', (e) => {
			if (!e.target.closest('.relevanssi-live-search-results') && !e.target.closest('.search--main') && !$formSearch.classList.contains('hidden')) {
				$btnToggleSearch.classList.remove('focus');
				$formSearch.classList.add('hidden');
			}
		}, false);

		// Secure closing of Search when accessing a submenu
		let $parentsNav = document.querySelectorAll('.menu-wrap .has-submenu');
		Array.prototype.forEach.call($parentsNav, ($parentNav) => {
			$parentNav.addEventListener('mouseover', (e) => {
				if (!$formSearch.classList.contains('hidden')) {
					$btnToggleSearch.classList.remove('focus');
					$formSearch.classList.add('hidden');
				}
			}, false);
		});

	}

}