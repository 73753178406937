const selector = '.btn--backtotop';

export const backtotopHandler = {

    init: function () {
        backtotopHandler.checkOffset();
        backtotopHandler.setButton();
    },
    checkOffset(){

        if (!document.querySelector(selector))
            return;
        
        let $button = document.querySelector(selector);
        
        setOffset();
        window.addEventListener('resize', setOffset);
        
        function setOffset(){
            // List elements that imply bottom Offset
            let $elements = document.querySelectorAll('.posts--filters');

            let isMobile = document.body.classList.contains('mobile') ? true : false;
    
            if(isMobile && $elements.length > 0){
                $button.classList.add('mobile-offset-b');
            } else {
                $button.classList.remove('mobile-offset-b');
            }
        }

    },

    setButton() {

        if (!document.querySelector(selector))
            return;

        let $button = document.querySelector(selector);

        $button.addEventListener('click', () => {
            window.scrollTo({
                'top': 0,
                behavior: 'smooth'
            });
        });

        window.addEventListener('scroll', function (event) {
            let topOffset = window.pageYOffset;

            if (topOffset < 200) {
                $button.classList.add('hidden');
            } else {
                $button.classList.remove('hidden');
            }

        });

    }

}