// import * as _utils from './_utils';
import { a11y } from './modules/a11y';
import { mobileHandler } from './modules/mobile';
import { formsHandler } from './modules/forms';
import { ui } from './modules/ui';
// import { videosHandler } from './modules/videos';
// import { clipBoardHandler } from './modules/clipboard';
import { searchMain } from './components/searchMain';
import { sharersHandler } from './modules/sharers';
import { backtotopHandler } from './modules/backtotop';
import { ajaxForm } from './modules/ajaxform';
import { loadMore } from './modules/loadmore';
import { archives } from './modules/archives';
import { tabsHandler } from './components/tabs';

// import './components/slider.js';

// import Swiper, { Navigation } from 'swiper';

// init Swiper:
// Swiper.use([Navigation]);

document.addEventListener('DOMContentLoaded', function (event) {
	a11y.init();
	mobileHandler.init();
	ui.init();
	formsHandler.init();
	tabsHandler.init();
	// videosHandler.init();
	// mainHandler.init();
	searchMain.init();
	sharersHandler.init();
	backtotopHandler.init();
	ajaxForm.init();
	loadMore.init();
	archives.init();

	// if(document.body.classList.contains('thematiques')){
	// 	thematiquesHandler.init();
	// }

	// ui.init();
});


/***********************************
 MAIN
***********************************/
let mainHandler = {

	init: function () {


	}

};
