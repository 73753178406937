import { mobileBreakpoint, getViewportWidth } from '../modules/mobile';

export const tabsHandler = {
    init: () => {
        if (document.querySelector('.formation__tabs')) {
            tabsHandler.setTabs();
        }
    },

    setTabs: function () {

        let $buttons = document.querySelectorAll('.btn-tab');
        let $tabs = document.querySelectorAll('.tab__content');

        // Show first Tab
        let $firstTab = $buttons[0].dataset.tab;
        showTab($firstTab);

        // Tabs switch
        Array.prototype.forEach.call($buttons, ($button) => {
            $button.addEventListener('click', () => {
                let tab = $button.dataset.tab;
                // console.log(tab);
                if ($button.classList.contains('active')) {
                    let vw = getViewportWidth();
                    if (vw <= mobileBreakpoint) {
                        closeTabs();
                    }
                } else {
                    showTab(tab);
                }
            });
        });

        // Show Tab function
        function showTab(id) {

            let $tab = document.querySelector('#tab-' + id);
            let $tabInner = $tab.querySelector('.tab__content-inner');
            let innerHeight = $tabInner.clientHeight;
            let btnHeight = $tab.querySelector('.btn-tab').clientHeight;

            closeTabs();

            // Toggle matching buttons (Desktop & Mobile)
            let $activeBtns = Array.from($buttons).filter(elem => elem.matches('[data-tab="' + id + '"]'));
            Array.prototype.forEach.call($activeBtns, ($button) => {
                $button.classList.add('active');
            });

            // Toggle Tab
            $tab.classList.add('active');
            // $tabInner.style.paddingTop = btnHeight + 'px';
            $tab.style.height = innerHeight + btnHeight + 'px';
        }

        function closeTabs() {
            Array.prototype.forEach.call($buttons, ($button) => {
                $button.classList.remove('active');
            });
            Array.prototype.forEach.call($tabs, ($tab) => {
                let $tabInner = document.querySelector('.tab__content-inner');
                $tab.classList.remove('active');
                $tab.style.height = '';
                // $tabInner.style.paddingTop = 0;
            });
        }
    }
}