export const loadMore = {

    init: function () {
        loadMore.events();
    },
    events(){
      if(document.querySelectorAll('#next-page').length == 0){
        return;
      }
      let idnameresults = "results";
      let nextButton = document.querySelector('#next-page');
      let refreshDiv = document.querySelector('#' + idnameresults);
      let loadingBloc = document.querySelector('#loading-pagination');
      let isLoading = false;

      let loadMoreEvent = function(){
        isLoading = true;
        nextButton.style.display = "none";

        // loadingBloc.classList.remove('visually-hidden');
        loadingBloc.style.display = "block";

        let xhr = new XMLHttpRequest();

        let stringDatas = "";
        let formData = "";

        let postUrl = nextButton.href;
        //postUrl = postUrl + '?is_ajax=1';

        xhr.open("POST", postUrl);
         xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            loadingBloc.classList.add('visually-hidden');
            loadingBloc.style.display = "none";
            var template = document.createElement('template');
            template.innerHTML = xhr.response;
            if(template.content.querySelector('#' + idnameresults) !== null){
              refreshDiv.innerHTML = refreshDiv.innerHTML + template.content.querySelector('#' + idnameresults).innerHTML;
            }

            if(template.content.querySelector('#next-page') !== null){
              nextButton.href = template.content.querySelector('#next-page').href;
              nextButton = document.querySelector('#next-page');
              nextButton.style.removeProperty('display');
            }else{
              nextButton.parentNode.removeChild(nextButton);
            }


            isLoading = false;
          }
        }

        xhr.send(formData);
      }
      nextButton.addEventListener('click', function(event){
        event.preventDefault();
        loadMoreEvent();
        return false;
      });


      /*  window.addEventListener('scroll', function(e) {
          if(nextButton !== null){
            if(document.querySelectorAll('#next-page').length > 0 && isLoading == false){
              let windowY = window.pageYOffset;
              let windowHeight = window.innerHeight;
              let buttonPosition = nextButton.getBoundingClientRect();
              let buttonY = buttonPosition.top;
              if(windowHeight > buttonY){
                loadMoreEvent();
              }
            }
          }
        });*/



    },


}
