export const formsHandler = {
    init: function () {
        formsHandler.checkbox();
        formsHandler.tags();
        formsHandler.radio();
        formsHandler.date();


        // formsHandler.resetFilters();

        formsHandler.conditionalLogic();
    },

    tags: function () {
        let $tags = document.querySelectorAll('.tag input[type="checkbox"]');
        let resetTag;

        if ($tags) {

            resetTag = Array.from($tags).filter(node => node.dataset.tags === "all");
            resetTag = resetTag[0];
            Array.prototype.forEach.call($tags, function ($tag, i) {
                // Set once
                setTag($tag);
                // Toggle on change
                $tag.addEventListener('change', function () {
                    if ($tag.dataset.tags === "all") {
                        resetTags();
                    } else {
                        resetTag.closest('.tag, .tag-alt').classList.remove('checked');
                        resetTag.checked = false;
                        setTag($tag);
                    }
                    submitTags();
                });
            });
        }

        function setTag($tag) {
            // $tag.disabled = false;
            if ($tag.checked === true) {
                $tag.closest('.tag, .tag-alt').classList.add('checked');
                $tag.closest('.tag, .tag-alt').classList.remove('indeterminate');
            } else if ($tag.indeterminate === true) {
                $tag.closest('.tag, .tag-alt').classList.add('indeterminate');
            } else {
                $tag.closest('.tag, .tag-alt').classList.remove('checked', 'indeterminate');
            }
        }

        function resetTags() {
            Array.prototype.forEach.call($tags, function ($tag, i) {
                // $tag.disabled = false;
                $tag.closest('.tag, .tag-alt').classList.remove('checked', 'indeterminate');
                $tag.checked = false;
            });
            resetTag.closest('.tag, .tag-alt').classList.add('checked');
            resetTag.checked = true;
        }

        function submitTags() {
            Array.prototype.forEach.call($tags, function ($tag, i) {
                // $tag.disabled = true;
                $tag.closest('.tag, .tag-alt').style.opacity = "0.6";
                $tag.closest('.tag, .tag-alt').style.pointerEvents = "none";
                // $tag.style.userSelect = "none";
            });
            setTimeout(() => {
                let $form = document.querySelector('form.blog-filters__form');
                // Array.prototype.forEach.call($tags, function ($tag, i) {
                //     $tag.disabled = false;
                // });
                $form.submit();
            }, 500)
        }
    },

    checkbox: function () {

        let $checkboxes = document.querySelectorAll('.checkbox input[type="checkbox"]');
        if ($checkboxes) {
            Array.prototype.forEach.call($checkboxes, function ($checkbox, i) {
                // Set once
                setCheckbox($checkbox);
                // Toggle on change
                $checkbox.addEventListener('change', function () {
                    setCheckbox($checkbox);
                });
            });
        }

        function setCheckbox($checkbox) {
            if ($checkbox.checked === true) {
                $checkbox.closest('.checkbox, .checkbox-alt').classList.add('checked');
                $checkbox.closest('.checkbox, .checkbox-alt').classList.remove('indeterminate');
            } else if ($checkbox.indeterminate === true) {
                $checkbox.closest('.checkbox, .checkbox-alt').classList.add('indeterminate');
            } else {
                $checkbox.closest('.checkbox, .checkbox-alt').classList.remove('checked', 'indeterminate');
            }
        }

    },

    radio: function () {

        let $radios = document.querySelectorAll('.radio input[type="radio"]');
        if ($radios) {
            Array.prototype.forEach.call($radios, function ($radio, i) {
                // Set once
                setRadio($radio);
                // Toggle on change
                $radio.addEventListener('change', function () {
                    let $group = $radio.closest('.radio-group');
                    if ($group) {
                        resetRadioGroup($group);
                    } else {
                        setRadio($radio);
                    }
                });
            });
        }

        function setRadio($radio) {
            if ($radio.checked === true) {
                $radio.closest('.radio, .radio-alt').classList.add('checked');
            } else {
                $radio.closest('.radio, .radio-alt').classList.remove('checked');
            }
        }

        function resetRadioGroup($group) {
            let $radios = $group.querySelectorAll('.radio input[type="radio"]');
            if ($radios) {
                Array.prototype.forEach.call($radios, function ($radio, i) {
                    setRadio($radio)
                });
            }
        }

    },

    date: function () {

        let $dates = document.querySelectorAll('.date input');
        if ($dates) {
            Array.prototype.forEach.call($dates, function ($date, i) {

                $date.addEventListener('blur', function () {
                    setDatePlaceholder($date);
                });

                $date.addEventListener('focusin', function () {
                    setDatePlaceholder($date);
                });

            });
        }

        function setDatePlaceholder($fieldDate) {
            let $placeholder = $fieldDate.parentNode.querySelector('.date-placeholder');
            if ($fieldDate.value && $fieldDate.value.split('-').length > 0) {
                let date = $fieldDate.value.split('-');
                $placeholder.innerHTML = date[2] + '/' + date[1] + '/' + date[0];
                $placeholder.classList.add('has-value');
            } else {
                $placeholder.innerHTML = $placeholder.dataset.label;
                $placeholder.classList.remove('has-value');
            }
        }

    },

    conditionalLogic: () => {
        let $fieldsConditional = document.querySelectorAll('input[data-conditional-trigger]');
        let $fieldsHidden = document.querySelectorAll('input[data-conditional]');

        Array.prototype.forEach.call($fieldsConditional, function ($fieldTrigger, i) {
            // Init
            if ($fieldTrigger.checked) {
                let logic = $fieldTrigger.dataset.conditionalTrigger;
                toggleFields(logic);
            }
            // onChange
            $fieldTrigger.addEventListener('change', () => {
                let logic = $fieldTrigger.dataset.conditionalTrigger;
                toggleFields(logic);
            });
        });

        function toggleFields(logic) {
            let $showFields = Array.from($fieldsHidden).filter(node => node.dataset.conditional === logic);
            Array.prototype.forEach.call($fieldsHidden, function ($field, i) {
                $field.closest('.input--field').style.display = 'none';
                if ($field.hasAttribute('required')) {
                    $field.dataset.required = 'true';
                    $field.removeAttribute('required');
                }
            });
            Array.prototype.forEach.call($showFields, function ($field, i) {
                $field.closest('.input--field').style.display = 'block'
                if ($field.dataset.required === 'true') {
                    $field.removeAttribute('data-required');
                    $field.setAttribute('required', 'required');
                }
            });
        }

    }

}