const selector = '.ajax-form';

export const ajaxForm = {

  init: function () {
    ajaxForm.parse();
  },
  parse() {
    // console.log("yooo")
    if (!document.querySelector(selector))
      return;

    let forms = document.querySelectorAll(selector);

    for (var i = 0; i < forms.length; i++) {
      let form = forms[i];
      let formName = form.getAttribute('data-name');
      if (formName !== null && formName != "") {
        let blockReponse = form.querySelector(".response");
        let blockForm = form.querySelector(".content-form");
        form.addEventListener('submit', function (event) {
          blockReponse.innerHTML = "";
          event.preventDefault();
          let formData = new FormData(form);
          formData.append("action", "submit_" + formName);
          let xhr = new XMLHttpRequest();
          xhr.open("POST", themeinfos.ajaxurl);
          form.classList.add('loading');
          form.classList.remove('success', 'error');
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              let responses = JSON.parse(xhr.response);
              let htmlReponse = "";

              // console.log(responses);
              if (responses.errors.length > 0) {
                form.classList.add('error');
                blockReponse.classList.add('errors');
                for (let j = 0; j < responses.errors.length; j++) {
                  htmlReponse += '<p>' + responses.errors[j] + '</p>';
                }
                let inputFailed = responses.errorFields;
                for (let index = 0; index < inputFailed.length; index++) {

                  // console.log(inputFailed[index]);
                  form.querySelector('input[name="' + inputFailed[index] + '"], select[name="' + inputFailed[index] + '"],textarea[name="' + inputFailed[index] + '"]').closest('.input--field').classList.add('error')
                  // htmlReponse += '<p><strong>' + responses.errors[j] + '</strong></p>';
                }
              } else if (responses.successes.length > 0) {
                form.classList.add('success');
                blockReponse.classList.remove('errors');
                for (let j = 0; j < responses.successes.length; j++) {
                  htmlReponse += '<p>' + responses.successes[j] + '</p>';
                }
                form.querySelectorAll('input[type="email"]')[0].value = "";
                blockForm.style.display = "none";

              }
              blockReponse.innerHTML = htmlReponse;
              //divResponse.innerHTML = xhr.response;
              form.classList.remove('loading');
            }
          }
          xhr.send(formData);
          return false;
        });
      }
    }




  },


}
